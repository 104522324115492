<template>
  <div class="my-project-requests-publishing">
    <div class="container-xl">
      <div class="card">
        <div class="card-header">
          <div class="card-title">Публикация проекта</div>
        </div>
        <div class="card-body">
          <div class="form-group float-right">
            <button @click="$router.back()" class="btn btn-link" type="button">
              <i class="bi-arrow-return-left"></i>
              Обратно
            </button>
          </div>
          <div class="form-group">
            <button @click="publish()" type="button" class="btn btn-success">Опубликовать</button>
          </div>
          <detailed-project :uuid="uuid" :buy="false"/>
        </div>
      </div>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import { DetailedProject, Processing } from "@/components/common";
import { createProjectRequestManager } from "@/utils/di";
import { DashboardEventType } from "../../../enums/DashboardEventType";

export default {
  name: "Publishing",
  components: {
    DetailedProject,
    Processing
  },
  data() {
    return {
      uuid: this.$route.params.uuid,
      processing: false
    };
  },
  methods: {
    async publish() {
      this.processing = true;
      try {
        const manager = await createProjectRequestManager();
        await manager.publish(this.uuid);
        this.$emit("dashboardEvent", DashboardEventType.ProjectPublished);
        await this.$router.push({ name: "myProjects" });
      } finally {
        this.processing = false;
      }
    }
  }
}
</script>
